.answer1 {
  width: 900px;
  padding: 25px;
  margin: 0 auto;
  border-radius: 20px;
  border: 1px solid lightgrey;
}

.mindset {
  width: 900px;
  padding: 25px;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid lightgrey;
  border-radius: 20px;
}

.newCharts {
  display: flex;
  width: 900px;
  margin: auto;
  border: 1px solid blue;
  flex-wrap: wrap;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 70em) {
  /* CSS rules here */
  .answer1 {
    width: 700px;
  }

  .mindset {
    width: 700px;
  }

  .newCharts {
    width: 700px;
  }
}

@media only screen and (max-width: 45em) {
  /* CSS rules here */
  .answer1 {
    width: 360px;
  }

  .mindset {
    width: 360px;
  }

  .newCharts {
    width: 360px;
  }
}

h1 {
  margin: 20px;
  font-size: 25px;
}

.charts {
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.bar {
  padding: 5px;
  margin: 10px;
  font-size: 25px;
  font-weight: bold;
  border-radius: 20px;
  color: black;
}

.pie {
  border: 1px solid red;
  width: 100%;
  height: 100%;
  padding: 5px;
  border-radius: 20px;
}

.vertical {
  padding: 5px;
  margin: 10px;
  border-radius: 20px;
}

.logo {
  display: block;
  width: 200px;
  margin: 20px auto;
}

.Doughnut {
  width: 300px;
  height: 300px;
  margin: 10px;
  padding: 20px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 6px 21px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 6px 21px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 6px 21px -6px rgba(0, 0, 0, 0.75);
}

.Bar {
  width: 300px;
  height: 300px;
  color: black;
  margin: 10px;
  padding: 20px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 6px 21px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 6px 21px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 6px 21px -6px rgba(0, 0, 0, 0.75);
}

.HoriBar {
  width: 300px;
  height: 300px;
  margin: 10px;
  padding: 20px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 6px 21px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 6px 21px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 6px 21px -6px rgba(0, 0, 0, 0.75);
}

.Polar {
  width: 300px;
  height: 300px;
  margin: 10px;
  padding: 20px;
  border-radius: 20px;
  -webkit-box-shadow: 0px 6px 21px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 6px 21px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 6px 21px -6px rgba(0, 0, 0, 0.75);
}

.CardGroup {
  display: none;
  padding: 20px;
}

.Card {
  margin: 20px;
  width: 200px;
  height: 200px;
  border: 1px solid blue;
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.Card:hover {
  width: 220px;
  height: 220px;
}

a:active {
}

.Adimg {
  height: 100%;
  width: 100%;
}

.legend {
  margin: auto;
  width: 350px;
  display: flex;
  justify-content: space-evenly;
  border: 1px solid yellow;
}

.legenditem {
  border: 1px solid blue;
}

.Custom {
  width: 360px;
  height: 380px;
  display: flex;
  margin: 20px auto;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: flex-end;
  border-radius: 20px;
  border: 2px solid #d0d8e0;
}

.Value {
  margin: 0;
  color: white;
  width: 100%;
  background-color: white 0.8;
}

.Legend {
  width: 360px;
  margin: 0px auto;
  display: flex;
  justify-content: space-evenly;
}
