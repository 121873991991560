.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.downloadPDF {
  font-size: 20px;
  width: 350px;
  height: 80px;
  display: block;
  margin: 40px auto;
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: #ed3330;
  padding: 20px;
  border-radius: 5px;
  border: none;
  transition: all 0.4s ease 0s;
  box-shadow: 0 9px #999;
  outline: none;
}

.downloadPDF:hover {
  width: 300px;
  background: #434343;
  letter-spacing: 1px;
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
  box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
  transition: all 0.4s ease 0s;
  outline: none;
}

.downloadPDF:active {
  transform: translateY(4px);
  outline: none;
  box-shadow: 0 5px #666;
  background: #04c924;
}
